import { SuccessToastConfig, ErrorToastConfig } from "app/utils/ToastConstants";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "app/utils/utility.service";
import { Loading } from "app/components";
import { getSingleUser, updateUser } from "./my-profile.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { InputTextarea } from "primereact/inputtextarea";

const OtherDetails = () => {
    const [values, setValues] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const sessionValues = getSessionUserInformation();

    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };

    const recordId = sessionValues.id;

    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const validateInput = () => {
        if (
            !values.referenceOne ||
            !values.referenceTwo ||
            !values.minJoiningTime ||
            !values.briefDescriptionJob ||
            !values.grand_total_experience
        ) {
            return false;
        }
        if (isNaN(values.grand_total_experience)) {
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        if (!validateInput()) {
            return false;
        }
        setIsLoading(true);
        if (recordId) {
            // let formData = new FormData();

            // for (const key of Object.keys(values)) {
            //     formData.append(key, `${values[key]}`);
            // }
            const record = await updateUser(recordId, values);
            if (record?.status === false) {
                setIsLoading(false);
                showError(record?.message);
            } else {
                showSuccess("Other details record updated successfully");
                // insert settings value
                setValues(null);
                setValues(record?.data);
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            let record = await getSingleUser(recordId);
            setValues(record);
            setIsLoading(false);
        };
        if (recordId) {
            fetchUser();
        }
    }, []);

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const cardFooter = (
        <span className="ml-3">
            {/* <Link to="/clients" style={{textDecoration:"none"}}> */}

            {/* </Link> */}
            <Button
                // onClick={handleSubmit}
                label={"Save"}
                className="p-button-raised p-button-warning p-button-lg"
            />
        </span>
    );
    return (
        <div className="center-align">
            {isLoading && <Loading />}
            <Toast ref={toast} className="ToastMessage" />
            <div className="RegisterForm">
                <form method="post" onSubmit={handleSubmit}>
                    <Card
                        // title="Other Details"
                        // subTitle="You can only register if you are citizen of India"
                        footer={cardFooter}
                    >
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Reference 1 (with full address and contact
                                    numbers)*
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={true}
                                            name="referenceOne"
                                            value={values?.referenceOne}
                                            onChange={handleInputChange}
                                        />

                                        {formSubmitted &&
                                            !values?.referenceOne && (
                                                <small className="p-error">
                                                    Please enter reference one
                                                </small>
                                            )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Reference 2 (with full address and contact
                                    numbers)*
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={true}
                                            name="referenceTwo"
                                            value={values?.referenceTwo}
                                            onChange={handleInputChange}
                                        />

                                        {formSubmitted &&
                                            !values?.referenceTwo && (
                                                <small className="p-error">
                                                    Please enter reference two
                                                </small>
                                            )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    If selected minimum time required to join
                                    NCRA-TIFR*
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={true}
                                            name="minJoiningTime"
                                            value={values?.minJoiningTime}
                                            onChange={handleInputChange}
                                        />

                                        {formSubmitted &&
                                            !values?.firstname && (
                                                <small className="p-error">
                                                    This field is required
                                                </small>
                                            )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Any Other relevant information / Remarks
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={false}
                                            name="otherRemarks"
                                            value={values?.otherRemarks}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-9 md:col-9 lg:col content pr-4 pl-4">
                                <label className="text-center">
                                    Brief Description of Job. *
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="briefDescriptionJob"
                                            value={values?.briefDescriptionJob}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                    {formSubmitted &&
                                        !values?.briefDescriptionJob && (
                                            <small className="p-error">
                                                This field is required
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-3 md:col-3 lg:col content pr-4 pl-4">
                                <label className="text-center">
                                    Grand Total Experience. *
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={true}
                                            name="grand_total_experience"
                                            value={
                                                values?.grand_total_experience
                                            }
                                            onChange={handleInputChange}
                                            disabled={false}
                                        />
                                        {formSubmitted &&
                                            !values?.grand_total_experience && (
                                                <small className="p-error">
                                                    This field is required
                                                </small>
                                            )}
                                            {formSubmitted &&
                                            isNaN(values?.grand_total_experience) && (
                                                <small className="p-error">
                                                    This field must be in numeric format
                                                </small>
                                            )}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Whether you were Offered to join NCRA-TIFR
                                    for any Posts & did not join. If Yes, Please
                                    give complete details of it
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={false}
                                            name="offeredJob"
                                            value={values?.offeredJob}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Have you been detained in Police Custody? If
                                    Yes, Please give complete details of it
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={false}
                                            name="policeCustody"
                                            value={values?.policeCustody}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Have you been convicted by any Court of Law
                                    or not? If Yes, Please give complete details
                                    of it
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={false}
                                            name="convictedCourtLaw"
                                            value={values?.convictedCourtLaw}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <label>
                                    Whether any criminal case is pending or
                                    contemplated against you in Court of Law? If
                                    Yes, Please give complete details of it
                                </label>
                                <div>
                                    <span className="p-float-label">
                                        <InputTextarea
                                            className="vw-input-nfl"
                                            autoComplete="off"
                                            required={false}
                                            name="criminalCase"
                                            value={values?.criminalCase}
                                            onChange={handleInputChange}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Card>
                </form>
            </div>
        </div>
    );
};

export default OtherDetails;
